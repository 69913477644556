import type { PropsWithChildren } from 'react';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';
import type { QueryResult } from 'react-apollo';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { markErrorAsHandled } from '@confluence/graphql';
import { isUnauthorizedError, isNotFoundError } from '@confluence/error-boundary';
import { usePageContentId, usePageSpaceKey, usePageState } from '@confluence/page-context';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { isNotFoundSpaceError } from '@confluence/content-unified-query/entry-points/errors';
import type { Route } from '@confluence/route';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { NotFoundContained } from './NotFoundContained';
import { ContentExpansionContainer } from './styles';
import { UnauthorizedScreen } from './UnauthorizedScreen';
import { ContentRedirect } from './ContentRedirect';
import { ContentPrerequisitesQuery } from './ContentPrerequisitesQuery.graphql';
import type {
	ContentPrerequisitesQuery as ContentPrerequisitesQueryType,
	ContentPrerequisitesQueryVariables,
} from './__types__/ContentPrerequisitesQuery';

type ContentNodeType = {
	space: {
		key: string | null;
		alias: string | null;
	} | null;
};

type ContentQueryDataType = {
	content: {
		nodes: Array<ContentNodeType | null> | null;
	} | null;
};

type ContentQueryResultType = Pick<QueryResult<ContentQueryDataType>, 'data' | 'error' | 'loading'>;

type ContentPrerequisitesParams = {
	contentId?: string;
	spaceKey?: string;
	contentQueryResult?: ContentQueryResultType;
	handleUnexpectedError?: (error: Error) => void;

	skip?: boolean;
};

type ContentPrerequisitesResult = {
	isLoading?: boolean;
	isNotFound?: boolean;
	isRedirect?: boolean;
	isRestricted?: boolean;
	isUnexpectedError?: boolean;

	// Set when `isRedirect` is true and specifies the space key to redirect to
	redirectSpaceKey?: string | null;

	// Set when error is propagated from ContentQuery or from ContentPrerequisitesQuery
	error?: ApolloError;
};

const useContentPrerequisites = ({
	spaceKey,
	contentId,
	contentQueryResult,
	handleUnexpectedError,
	skip,
}: ContentPrerequisitesParams): ContentPrerequisitesResult => {
	const shouldFailExperienceOnError = useBooleanFeatureFlag(
		'confluence.frontend.view.page.experience.errors',
	);

	// Redirect legacy url which doesn't have spaceKey
	const missingSpaceKey = !spaceKey;
	const missingContentId = !contentId;

	// Content is considered missing when we attempted to use contentQueryResult but no data
	// is available due to known or unknown errors. This is only applicable for SSR.
	const missingContentSSR = process.env.REACT_SSR && contentQueryResult && !contentQueryResult.data;

	const skipContentPrerequisites =
		skip || missingContentId || (Boolean(contentQueryResult) && !missingContentSSR);
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');

	const contentPrerequisitesQueryResult = useQuery<
		ContentPrerequisitesQueryType,
		ContentPrerequisitesQueryVariables
	>(ContentPrerequisitesQuery, {
		errorPolicy: 'all',
		variables: {
			contentId: contentId!,
			missingSpaceKey,
			spaceKey,
			includeAlias: isSpaceAliasFFEnabled,
		},
		skip: skipContentPrerequisites,
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const {
		data,
		error,
		loading: queryLoading,
	} = skipContentPrerequisites ? contentQueryResult || {} : contentPrerequisitesQueryResult;

	const [{ contentIdLoading }] = usePageState();

	const loading = queryLoading || contentIdLoading;

	if ((!data || missingContentId) && loading) {
		return { isLoading: true };
	}

	// Known Apollo bug: Errors are not saved within Apollo in-memory cache
	// https://github.com/apollographql/apollo-client/issues/4806. For restricted spaces and pages,
	// the 403 error is subsequently undefined after retrieval attempt. Restricted spaces means
	// content will be null, but the error might be undefined.
	const isEmptyContent = !loading && !error && data && !data?.content;

	if ((error && isUnauthorizedError(error)) || isEmptyContent) {
		if (error) markErrorAsHandled(error);
		if (isEmptyContent && !process.env.REACT_SSR) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					source: 'ContentPrerequisites',
					action: 'returnedEmpty',
					actionSubject: 'content',
					attributes: {
						contentId,
						skip,
						hadContentQueryData: Boolean(contentQueryResult?.data),
					},
				},
			}).fire();
		}

		return {
			error,
			isRestricted: true,
		};
	}

	// Catch all for errors, no content and calls that return null/undefined
	// If FF is on, this catches Not Found errors (404) and other errors are handled below
	const content = data?.content?.nodes || [];
	const shouldHandleErrors = shouldFailExperienceOnError && !!error;

	if (
		missingContentId ||
		(content.length === 0 && !shouldHandleErrors) ||
		(shouldHandleErrors && isNotFoundError(error))
	) {
		return {
			isNotFound: true,
		};
	}
	const expectedSpaceKey =
		(isSpaceAliasFFEnabled && content[0]?.space?.alias) || content[0]?.space?.key;
	// Checks for case where old / wrong spacekey is provided causing reload
	// on SSR -> SPA transition once the ContentRedirectQuery resolves.
	const wrongSpaceKeyOnSSR = process.env.REACT_SSR && expectedSpaceKey !== spaceKey;

	if (missingSpaceKey || wrongSpaceKeyOnSSR || (error && isNotFoundSpaceError(error))) {
		markErrorAsHandled(error);
		return {
			isRedirect: true,
			redirectSpaceKey: expectedSpaceKey,
		};
	}

	if (error && handleUnexpectedError) {
		handleUnexpectedError(error);
		return {
			error,
			isUnexpectedError: true,
		};
	}

	return {};
};

export type ContentPrerequisitesProps = PropsWithChildren<{
	route: Route;
	contentQueryResult?: ContentQueryResultType;
	passThroughOnLoading?: boolean;
	handleUnexpectedError?: (error: Error) => void;
}>;

/**
 * The common error and restrictions handling component for all content types.
 *
 * Newer content types can create their own prerequisites component to define their component composition
 * or custom error handling paths, and leverage `ContentPrerequisites` to handle the common error cases.
 *
 * @param route - the current route object
 * @param contentQueryResult - the content query result from the parent component e.g. ContentUnifiedQuery
 * @param passThroughOnLoading - whether to render null or pass through the component and let itself handle
 * the loading state when query is loading
 */
export const ContentPrerequisites = ({
	children,
	route,
	contentQueryResult,
	passThroughOnLoading = false,
	handleUnexpectedError,
}: ContentPrerequisitesProps) => {
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();

	const prerequisites = useContentPrerequisites({
		spaceKey,
		contentId,
		contentQueryResult,
		handleUnexpectedError,
	});

	if (prerequisites.isNotFound) {
		return <NotFoundContained route={route} spaceKey={spaceKey} />;
	}

	const getContentComponent = () => {
		if (prerequisites.isLoading) {
			return passThroughOnLoading ? (
				<ContentExpansionContainer>{children}</ContentExpansionContainer>
			) : null;
		}

		if (contentId && prerequisites.isRestricted) {
			return (
				<UnauthorizedScreen contentId={contentId} accessType={getAccessType(prerequisites.error)} />
			);
		}

		if (prerequisites.isRedirect) {
			return (
				<Redirection
					name={route.name}
					params={{
						contentId,
						spaceKey: prerequisites.redirectSpaceKey,
					}}
				/>
			);
		}

		return <ContentExpansionContainer>{children}</ContentExpansionContainer>;
	};

	return (
		<>
			<ContentRedirect />
			{getContentComponent()}
		</>
	);
};

function getAccessType(error: ApolloError | undefined): 'inherited' | 'view' {
	return error?.graphQLErrors?.[0]?.extensions?.data?.errors?.[0]?.message?.key ===
		'confluence.content.restricted.inherited'
		? 'inherited'
		: 'view';
}
