import React, { memo, useContext, useCallback } from 'react';

import { SPACE_OVERVIEW, VIEW_PAGE } from '@confluence/named-routes';
import { Redirection, RoutesContext, useNavigationPolicy } from '@confluence/route-manager';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';

type ContentRedirectComponentProps = {
	spaceKeyInUrl: string | undefined;
	contentSlugInUrl: string | undefined;
	contentWithSlug: any;
	space: any;
};

export const ContentRedirectComponent = memo(
	({
		contentWithSlug,
		space,
		spaceKeyInUrl,
		contentSlugInUrl = '',
	}: ContentRedirectComponentProps) => {
		const { match } = useContext(RoutesContext);
		const { routePolicy } = useNavigationPolicy();
		const isLivePage = useIsLivePage();

		const shouldRedirectIncorrectSpaceKey = useCallback(() => {
			if (isLivePage) {
				/*
				 * Live Pages exist on the view page route, but render the Confluence editor
				 * The Confluence editor has its own space key redirection logic and move event listeners (via useSpaceKeyWatcher)
				 * Consequently this redirection will clash with the redirects from the editor.
				 */
				return false;
			}
			return contentWithSlug && spaceKeyInUrl !== contentWithSlug?.spaceKey;
		}, [spaceKeyInUrl, contentWithSlug, isLivePage]);

		const shouldRedirectIncorrectSlug = useCallback(
			() =>
				// When current slug in URL does not match with slug in content ==> slug changed
				// Important to decode when compare because react router decodes the params first
				// so contentSlug will be decoded string
				decodeURIComponent(contentWithSlug?.slug || '') !== decodeURIComponent(contentSlugInUrl),
			[contentWithSlug?.slug, contentSlugInUrl],
		);

		const isRedirectNeeded = useCallback(
			() => shouldRedirectIncorrectSlug() || shouldRedirectIncorrectSpaceKey(),
			[shouldRedirectIncorrectSlug, shouldRedirectIncorrectSpaceKey],
		);

		// Don't redirect if we have insufficient information to successfully redirect the user
		if (!contentWithSlug) {
			return null;
		}

		// Do redirect if we should go back to the Space Home Overview Tab
		if (space && contentWithSlug.id === `${space.homepageId}` && match?.name === VIEW_PAGE.name) {
			const willPushInSelf =
				!routePolicy ||
				routePolicy.willPushInSelf(
					VIEW_PAGE.name,
					match?.params.contentId,
					SPACE_OVERVIEW.name,
					contentWithSlug.id,
				);

			return willPushInSelf ? (
				<Redirection
					name={SPACE_OVERVIEW.name}
					params={{
						spaceKey: contentWithSlug.spaceKey,
						contentId: contentWithSlug.id,
						contentSlug: contentWithSlug.slug,
					}}
				/>
			) : null;
		}

		// Don't redirect if we otherwise don't need to
		if (!isRedirectNeeded()) {
			return null;
		}

		return (
			<Redirection
				name={match?.name}
				params={{
					...match?.params,
					spaceKey: contentWithSlug.spaceKey,
					contentId: contentWithSlug.id,
					contentSlug: contentWithSlug.slug,
				}}
			/>
		);
	},
);
